<template>
  <div>
    <div v-if="isThereData">
    </div>
    <!-- Table -->
    <b-pagination size="sm" align="fill"
                  v-if="(getTotalItems / perPage) > 1"
                  v-model="currentPage"
                  :total-rows="getTotalItems"
                  :per-page="perPage"
                  :disabled="!getAvailableList"
                  aria-controls="notificaciones-table"
    ></b-pagination>
    <b-table
      id="notificacionesTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-function="filterFunction"
      @filtered="onFiltered"
      empty-filtered-text="No se encontraron notificaciones con el criterio de búsqueda escrito"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <template #cell(remitente.identificacion)="row">
        <filtered-row :value="row.value" :filter="filter"/>
      </template>

      <template #cell(remitente.email)="row">
        <filtered-row :value="row.value" :filter="filter"/>
      </template>

      <template #cell(asunto)="row">
        <filtered-row :value="row.item.asunto" :filter="filter"/>
      </template>

      <template #cell(fecha)="row">
        <filtered-row :value="row.item.fecha" :filter="filter"/>
      </template>

      <template #cell(archivos)="row">
        <span class="text-sm text-left w-100">
          <ul>
            <li v-for="archivo in row.item.archivos" v-bind:key="archivo.id">
              <b-button variant="link" @click="onDownloadFile(archivo)">
                {{ archivo.original_name }}
              </b-button>
            </li>
          </ul>
        </span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Ver notificaciones
        </b-button>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <notification-detalles :row="row"></notification-detalles>
      </template>
    </b-table>
    <loading v-else></loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NotificationDetalles from '@/components/Administracion/Notifications/NotificationDetalles'
import FilteredRow from '@/components/Common/Table/FilteredRow'

const API_MODULE = 'notificationsModule'

export default {
  name: 'NotificationsList',

  components: {
    NotificationDetalles,
    FilteredRow
  },

  props: {
    filter: {
      required: true
    }
  },

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'remitente.identificacion', label: 'Remitente' },
        { key: 'remitente.email', label: 'Dirección' },
        { key: 'asunto', label: 'Asunto' },
        { key: 'fecha', label: 'Fecha' },
        { key: 'show_details', label: 'Notificaciones' }
      ],

      perPage: 10,

      currentPage: 1
    }
  },

  computed: {
    ...mapGetters(API_MODULE, [
      'getList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ]),
    ...mapGetters('userModule', [
      'isAuthUserOrCloneUser'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${API_MODULE}/getResource`)

      if (error) this.$notify({ error, message }, 'Notificaciones')
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return row.remitente.identificacion.toLowerCase().includes(filter.toLowerCase()) ||
        row.remitente.email.toLowerCase().includes(filter.toLowerCase()) ||
        row.asunto.toLowerCase().includes(filter.toLowerCase()) ||
        row.fecha.toLowerCase().includes(filter.toLowerCase()) ||
        row.notificaciones.some((element, index) =>
          (element && element.destinatario && element.destinatario.email && element.destinatario.email.toLowerCase().includes(filter.toLowerCase())) ||
          (element && element.destinatario && element.destinatario.identificacion && element.destinatario.identificacion.toLowerCase().includes(filter.toLowerCase())) ||
          (element && element.message && element.message.body && element.message.body.toLowerCase().includes(filter.toLowerCase()))
        )
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    },

    async onDownloadFile (archivo) {
      const result = await this.$store.dispatch('archivoModule/download', archivo.id)

      await this.$onDownload(result, archivo.original_name)

      this.$notify({ message: `Descarga de archivo ${archivo.original_name}.` })
    }
  }
}
</script>
