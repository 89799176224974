<script>
import FilteredRow from '@/components/Common/Table/FilteredRow'
const API_MODULE = 'notificationsModule'

export default {
  name: 'NotificationDetalles',

  props: {
    row: {
      required: true
    }
  },

  components: {
    FilteredRow
  },

  data () {
    return {
      filter: '',
      notificacionesFields: [
        { key: 'destinatario.identificacion', label: 'Destinatario' },
        { key: 'destinatario.email', label: 'Dirección' },
        { key: 'message.body', label: 'Mensaje' }
      ],

      notificaciones: [],
      archivos: [],

      notificacionesPerPage: 5,

      notificacionesCurrentPage: 1,

      loading: false
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    async init () {
      this.loading = true
      const response = await this.$store.dispatch(`${API_MODULE}/getDetalles`, this.row.item.id)
      this.notificaciones = response.data
      this.loading = false
    }
  }
}
</script>

<template>
  <div>
    <b-card
      class="mb-1"
      border-variant="primary"
      header-bg-variant="primary"
      header-text-variant="white"
      header="Detalle"
      no-body
    >
      <b-card-body>
        <b-row class="mb-2">
          <b-col cols="12" md="12">
            <loading v-if="loading"></loading>
            <b-card
              header="Destinatarios"
              no-body
              v-else
              border-variant="primary"
              class="mb-4"
              style="overflow: auto;"
            >
              <b-table
                id="notificacionesTable"
                v-if="notificaciones && notificaciones.length > 0"
                :fields="notificacionesFields"
                :items="notificaciones"
                striped
                bordered
                responsive
                hover
                stacked="lg"
                show-empty
                small
                :per-page="notificacionesPerPage"
                :current-page="notificacionesCurrentPage"
              >
                <template #cell(destinatario.identificacion)="row">
                  <filtered-row :value="row.value" :filter="filter"/>
                </template>
                <template #cell(destinatario.email)="row">
                  <filtered-row :value="row.value" :filter="filter"/>
                </template>
                <template #cell(message.body)="row">
                      <span class="text-sm text-left w-100" :class="{ 'text-muted': row.item.is_deleted }">
                        <div v-html="row.value"></div>
                      </span>
                </template>
              </b-table>
              <b-pagination size="sm" align="fill"
                            v-if="notificaciones && (notificaciones.length / notificacionesPerPage) > 1"
                            v-model="notificacionesCurrentPage"
                            :total-rows="notificaciones.length"
                            :per-page="notificacionesPerPage"
                            aria-controls="notificaciones-table"
              ></b-pagination>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="archivos.length > 0">
          <b-col cols="12" md="12">
            <b-card
              header="Archivos"
              no-body
              border-variant="success"
              class="mb-4"
              style="overflow: auto;"
            >
                  <span class="text-sm text-left w-100">
                    <ul>
                      <li v-for="archivo in row.item.archivos" v-bind:key="archivo.id">
                        <b-button variant="link" @click="onDownloadFile(archivo)">
                          {{ archivo.original_name }}
                        </b-button>
                      </li>
                    </ul>
                  </span>
            </b-card>
          </b-col>
        </b-row>

        <b-card no-body>
          <b-button
            variant="white"
            size="sm"
            class="btn-outline-black"
            @click="row.toggleDetails"
          >
            Cerrar detalle
          </b-button>
        </b-card>
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped>

</style>
